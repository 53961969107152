import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import EmblaCarouselReact from "embla-carousel-react";
import Layout from "../components/Layout";
import styled from "styled-components";
import {EmblaButtons} from "../components/EmblaButtons";

const Slider = styled.div`
  display: flex;
  height: 100vh;
  & > div {
    flex: 1;
    min-height: 100%;
  }
  .gatsby-image-wrapper {
    min-height: 100%;
  }
  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    min-width: 500px;
    max-width: 500px;
    white-space: normal;
    font-size: 55px;
    text-align: center;
    color: white;
    text-transform: uppercase;
    @media (max-width: 800px) {
      font-size: 24px;
      h3 {
        max-width: 60vw;
        margin: 0 auto;
      }
    }
  }
`;

const Container = styled.div`
  position: relative;
`;


EmblaButtons.propTypes = {
  onClick: PropTypes.func,
  onClick1: PropTypes.func
};
const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const [embla, setEmbla] = useState(null);
  return (
    <Layout>
      <Container>
        <EmblaCarouselReact emblaRef={setEmbla} options={{loop: true}}>
          <Slider>
            {frontmatter.gallery.map((slide, i) => (
                <div key={i} style={{flex: "0 0 100%", position: "relative"}}>
                  <Img
                      fluid={slide.image.childImageSharp.fluid}
                      style={{margin: "0 auto", maxHeight: "100vh"}}
                  />
                  <div className="title">
                    <h3>{slide.text}</h3>
                  </div>
                </div>
            ))}
          </Slider>
          <EmblaButtons embla={embla} size="70"/>
        </EmblaCarouselReact>
      </Container>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        gallery {
          text
          image {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`;
