import React from "react";
import styled from "styled-components";
import { colors } from "../styles/variables";

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  padding: 0 10px;
  left: 10px;
  color: ${colors.gold};
  border: none;
  transition: 0.3s;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 20;
  &:hover {
    color: ${colors.accent};
  }
  &.right {
    right: 10px;
    left: unset;
  }
`;

export const EmblaButtons = ({ embla, size }) => {
  return (
    <>
      <Arrow
        className="left"
        onClick={() => embla.scrollPrev()}
        style={{ fontSize: size | 60 }}
      >
        {"‹"}
      </Arrow>
      <Arrow
        className="right"
        onClick={() => embla.scrollNext()}
        style={{ fontSize: size | 60 }}
      >
        {"›"}
      </Arrow>
    </>
  );
};
